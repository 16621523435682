export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/Chapala Logo.png',
    banner_img_root : '/img/chapalamexican-banner.png',
    resid : '',
    key_value : 'heartlanddemo',
    secret_value : 'heartlanddemo',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3067.6229106361347!2d-105.0852392!3d39.7481206!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b87857ad3c721%3A0x243903b9a8aadc94!2sChapala%20Mexican%20Restaurant!5e0!3m2!1sen!2sus!4v1650011794255!5m2!1sen!2sus',
    facebook_link:'#',
    RestaurantName:"Chapala Mexican Restaurant"
};
